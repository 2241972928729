import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {createSpace, getAllSpaces, getCurrentSpace, getCurrentSpaceStatistics, updateSpace} from "./API";

export const getCurrentSpaceThunk = createAsyncThunk(
    'spaces/getCurrent',
    async (spaceId) => {
        return getCurrentSpace(spaceId)
    }
)

export const getCurrentSpaceStatisticsThunk = createAsyncThunk(
    'spaces/getCurrentSpaceStatistics',
    async (spaceId) => {
        return getCurrentSpaceStatistics(spaceId)
    }
)

export const getSpacesThunk = createAsyncThunk(
    'spaces/getSpaces',
    async (spaceId) => {
        return getAllSpaces(spaceId)
    }
)

export const createSpaceThunk = createAsyncThunk(
    'spaces/createSpace',
    (data) => {
        return createSpace({
            space_name: data.spaceName
        })
    }
)

export const updateSpaceThunk = createAsyncThunk(
    'spaces/updateSpace',
    (data) => {
        return updateSpace(data.spaceId, {
            "space_name": data.data.space_name,
            "emails": data.data.avocados_users.map(user => user.email),
        })
    }
)


const SpacesSlice = createSlice({
    name: 'spaces',
    initialState: {current: {status: 'idle', data: null, statistics: null}, spaces: [], status: 'idle'},
    reducers: {
        exitSpace(state) {
            state.current = {status: 'idle', data: null}
        }
    },
    extraReducers: {
        [getCurrentSpaceThunk.pending]: (state, action) => {
            state.current.status = 'loading'
        },
        [getCurrentSpaceThunk.rejected]: (state, action) => {
            state.current.status = 'failed'
            state.current.error = action.error.message
        },
        // Add reducers for additional action types here, and handle loading state as needed
        [getCurrentSpaceThunk.fulfilled]: (state, action) => {
            state.current.status = 'idle'
            state.current.data = action.payload
        },
        [getSpacesThunk.pending]: (state, action) => {
            state.status = 'loading'
        },
        [getSpacesThunk.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        },
        // Add reducers for additional action types here, and handle loading state as needed
        [getSpacesThunk.fulfilled]: (state, action) => {
            state.status = 'idle'
            state.spaces = action.payload
        },
        [getCurrentSpaceStatisticsThunk.pending]: (state, action) => {
            state.current.status = 'loading'
        },
        [getCurrentSpaceStatisticsThunk.rejected]: (state, action) => {
            state.current.status = 'failed'
            state.current.error = action.error.message
        },
        // Add reducers for additional action types here, and handle loading state as needed
        [getCurrentSpaceStatisticsThunk.fulfilled]: (state, action) => {
            state.current.status = 'idle'
            state.current.statistics = action.payload
        },
    }
})

export default SpacesSlice.reducer
export const {exitSpace} = SpacesSlice.actions
