import React from 'react';
import {useFirebase} from "react-redux-firebase";
import {useNavigate} from "react-router-dom";
import {Menu} from "antd";
import {CalendarOutlined, SettingOutlined, SoundOutlined, UserOutlined} from '@ant-design/icons';
import UploadOutlined from "@ant-design/icons/lib/icons/UploadOutlined";
import {useSelector} from "react-redux";

export const UserMenu = () => {
    const navigate = useNavigate();

    const firebase = useFirebase();

    const currentSpace = useSelector((state) => {
        return state.spaces.current
    })

    const currentUser = useSelector((state) => {
        return state.users.current
    })

    const logout = () => {
        firebase.auth().signOut().then(() => {
            // Sign-out successful.
        }).catch((error) => {
            // An error happened.
        });
    }

    return (
        <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
            {currentSpace.data ? (
                <>
                    <Menu.Item onClick={() => navigate(`/spaces/${currentSpace.data.id}/recordings/`)} key="1"
                               icon={<SoundOutlined/>}>
                        Recordings
                    </Menu.Item>
                    <Menu.Item onClick={() => navigate(`/spaces/${currentSpace.data.id}/upload-recordings/`)}
                               key="2" icon={<UploadOutlined/>}>
                        Upload Recordings
                    </Menu.Item>
                    <Menu.Item onClick={() => navigate(`/spaces/${currentSpace.data.id}/calendar/`)}
                               key="3" icon={<CalendarOutlined/>}>
                        Calendar
                    </Menu.Item>
                    {currentSpace.data.space_owner.id === currentUser.id ? (
                        <Menu.Item onClick={() => navigate(`/spaces/${currentSpace.data.id}/settings/`)} key="4"
                                   icon={<SettingOutlined/>}>
                            Settings
                        </Menu.Item>
                    ) : null}
                </>) : null}

            <Menu.Item onClick={() => logout()} key="5" icon={<UserOutlined/>}>
                Logout
            </Menu.Item>
        </Menu>
    )
}
