import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {Alert, Calendar, Modal, Space, Tooltip} from "antd";
import {createAvailabilityThunk, deleteAvailabilityThunk, getAvailabilitiesThunk} from "../Feature/AvailabilitySlice";
import dayjs from 'dayjs';
import {CalendarOutlined, ExclamationCircleOutlined} from "@ant-design/icons";
import Gravatar from "react-gravatar"

const {confirm} = Modal;

export const CalendarView = () => {

    const [selectedDate, setSelectedDate] = useState(dayjs());
    const dispatch = useDispatch();

    const user = useSelector((state) => {
        return state.firebase.auth
    });

    const spaceId = useSelector((state) => {
        if (state.spaces.current.data) {
            return state.spaces.current.data.id
        } else {
            return null
        }
    })

    const availabilities = useSelector((state) => {
        return state.availabilities.availabilities
    })

    useEffect(() => {
        dispatch(getAvailabilitiesThunk(spaceId))
    }, [user, dispatch, spaceId]);

    const dateCellRender = (value) => {
        const listData = availabilities.filter(availability => {
            return value.startOf('day').isSame(dayjs(availability.date).startOf('day'))
        })

        return (
            <>
                {listData.map(item => (
                    <span key={item.id} style={{marginRight: '3px'}}>
                        <Tooltip placement="topLeft"
                                 overlayClassName={"comment-overlay"}
                                 title={`${item.user.first_name}`}>
                            <Gravatar size={20} email={item.user.email}/>
                        </Tooltip>
                    </span>

                ))}
            </>
        );
    }


    const deleteWithConfirmation = (availability) => {
        confirm({
            title: 'Remove availability?',
            icon: <ExclamationCircleOutlined/>,
            content: `${availability.date}`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                dispatch(deleteAvailabilityThunk({spaceId: spaceId, availabilityId: availability.id})).then(
                    () => dispatch(getAvailabilitiesThunk(spaceId))
                )
            }
        });
    }

    const createWithConfirmation = (value) => {
        confirm({
            title: 'Add availability?',
            icon: <CalendarOutlined style={{color: 'black'}}/>,
            content: `${value.format('YYYY-MM-DD')}`,
            okText: 'Yes',
            okType: 'primary',
            cancelText: 'No',
            onOk() {
                dispatch(createAvailabilityThunk({spaceId: spaceId, date: value.format('YYYY-MM-DD')})).then(
                    () => dispatch(getAvailabilitiesThunk(spaceId))
                )
            }
        });
    }

    const onSelect = value => {
        if (value.isSame(selectedDate, 'month')) {
            const existingAvailabilities = availabilities.filter(availability => {
                return value.startOf('day').isSame(dayjs(availability.date).startOf('day')) &&
                    user.email === availability.user.email
            })

            if (existingAvailabilities.length > 0) {
                deleteWithConfirmation(existingAvailabilities[0])
            } else {
                createWithConfirmation(value)
            }
        }
        setSelectedDate(value)
    };


    return (
        <Space direction="vertical">
            <h1>
                Calendar
            </h1>
            <Alert message="Click on a date to add or remove your availabilities." type="info"/>
            <Calendar value={selectedDate} onSelect={onSelect} cellRender={dateCellRender}/>
        </Space>
    );
}
