import React from "react";
import {DeleteOutlined, PlusOutlined, UserOutlined} from "@ant-design/icons";
import {Button, Divider, Form, Input} from "antd";

function UserEmailField() {
    return (
        <Form.List name="avocados_users">
            {(fields, {add, remove}) => {
                return (
                    <div>
                        {fields.map((field, index) => (
                            <div key={field.key}>
                                <Form.Item
                                    name={[index, "email"]}
                                    rules={[{required: true, type: "email", message: "The input is not valid email."}]}
                                >

                                    <Input placeholder="email@domain.com"
                                           prefix={<UserOutlined/>}
                                           suffix={
                                               <DeleteOutlined onClick={() => remove(field.name)}
                                                               style={{color: 'rgba(0,0,0,.45)'}}/>
                                           }/>
                                </Form.Item>


                            </div>
                        ))}
                        <Form.Item>
                            <Button
                                type="dashed"
                                onClick={() => add()}
                                style={{width: "100%"}}
                            >
                                <PlusOutlined/> Add Member
                            </Button>
                        </Form.Item>
                        <Divider/>
                    </div>
                );
            }}
        </Form.List>
    );
}

export default UserEmailField;
