import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {Upload} from "antd";
import InboxOutlined from "@ant-design/icons/lib/icons/InboxOutlined";
import {createRecording} from "../Feature/API";
import {getLabelsThunk} from "../Feature/LabelsSlice";
import {getRecordingsThunk} from "../Feature/RecordingsSlice";
import uuidv4 from "../Utils/uuid";


export const UploadRecordings = () => {
    const dispatch = useDispatch();

    const spaceId = useSelector((state) => {
        if (state.spaces.current.data) {
            return state.spaces.current.data.id
        } else {
            return null
        }
    })

    const [fileList, setFileList] = useState([]);

    const user = useSelector((state) => {
        return state.firebase.auth
    });

    useEffect(() => {


    }, [user]);

    const props = {
        name: 'file',
        multiple: true,
        beforeUpload(file) {
            const uuid = uuidv4()
            const template = {
                uid: uuid,
                name: file.name,
                status: 'uploading',
                url: null,

            }
            setFileList([...fileList, template])

            createRecording(spaceId, file).then(response => {
                const done = {status: 'done', url: response.file_uri}
                const obj = {...template, ...done}
                setFileList([...fileList.filter(song => song.uid !== uuid), obj])
            }).then(dispatch(getLabelsThunk(spaceId)).then(dispatch(getRecordingsThunk(spaceId))))
            return false
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
        previewFile(file) {
            console.log('Your upload file:', file);
            // Your process logic. Here we just mock to the same file
            return fetch('https://next.json-generator.com/api/json/get/4ytyBoLK8', {
                method: 'POST',
                body: file,
            })
                .then(res => res.json())
                .then(({thumbnail}) => thumbnail);
        }
    };
    return (
        <>
            <h1>
                Upload Recordings
            </h1>
            <div className={"upload-form"}>
                <Upload.Dragger {...props} fileList={fileList}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined/>
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                        Support for a single file upload.
                    </p>
                </Upload.Dragger>
            </div>
        </>
    );
}
