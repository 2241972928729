import {useDispatch, useSelector} from 'react-redux';
import {Button, Col, Input, Row, Tooltip} from 'antd';
import "./Player.css"

import {MessageTwoTone, PauseCircleOutlined, PlayCircleOutlined} from "@ant-design/icons";
import React, {useEffect, useRef} from "react";
import WaveSurfer from "wavesurfer.js";
import {createCommentThreadThunk, getCommentThreadsThunk} from "../Feature/CommentThreadsSlice";
import Gravatar from "react-gravatar";


export const Player = () => {
    const dispatch = useDispatch();

    const [wavesurfer, setWavesurfer] = React.useState(null);
    const [isReady, setIsReady] = React.useState(false);
    const [isPlaying, setIsPlaying] = React.useState(false);
    const [commentInputPosition, setCommentInputPosition] = React.useState(null);
    const [commentInputValue, setCommentInputValue] = React.useState("");

    const recording = useSelector((state) => {
        return state.recordings.currentRecording
    })

    const comments = useSelector((state) => {
        return state.commentThreads.commentThreads
    })

    const spaceId = useSelector((state) => {
        if (state.spaces.current.data) {
            return state.spaces.current.data.id
        } else {
            return null
        }
    })


    const waveformRef = useRef();
    const commentInputRef = useRef();

    useEffect(() => {
        if (waveformRef.current && !wavesurfer) {
            setWavesurfer(WaveSurfer.create({
                container: waveformRef.current,
                waveColor: 'violet',
                progressColor: 'purple',
                height: 50,
                mode: 'no-cors',
                responsive: true,
                normalize: true,
            }))
            //ws.on('ready', ws.play.bind(ws));
        }
    }, [wavesurfer]);

    useEffect(() => {
        if (wavesurfer) {
            wavesurfer.on('ready', wavesurfer.play.bind(wavesurfer));
            wavesurfer.on('ready', () => setIsReady(true));
        }
    }, [wavesurfer])

    useEffect(() => {
        if (recording) {
            setIsPlaying(true)
            wavesurfer.load(recording.mp3_file_uri)
            dispatch(getCommentThreadsThunk({spaceId: spaceId, recordingId: recording.id}))
            setIsReady(false)
        } else {
            setIsPlaying(false)
        }
    }, [recording, dispatch, spaceId, wavesurfer]);


    const playPause = () => {
        if (wavesurfer.isPlaying()) {
            wavesurfer.pause();
            setIsPlaying(false)
        } else {
            wavesurfer.play();
            setIsPlaying(true)
        }
    }


    const displayComment = (comment) => {
        return (<div key={comment.id + wavesurfer.getDuration()} className={"comment"}
                     style={{left: `${100 * comment.time / wavesurfer.getDuration()}%`}}>
            <Tooltip placement="topLeft"
                     overlayClassName={"comment-overlay"}
                     title={`${comment.author.first_name} - ${comment.message}`}>
                <Gravatar size={14} email={comment.author.email}/>
            </Tooltip>
        </div>)
    }

    const handleWaveClick = (event) => {
        let currentTargetRect = event.currentTarget.getBoundingClientRect();
        const event_offsetX = event.pageX - currentTargetRect.left;

        const commentPosition = wavesurfer.getDuration() * event_offsetX / currentTargetRect.width
        setCommentInputPosition(commentPosition)
        commentInputRef.current.focus()
    }

    const enterCommentInput = () => {
        if (!commentInputPosition) {
            setCommentInputPosition(wavesurfer.getCurrentTime())
        }
    }

    const sendCommentThread = (e) => {
        if (e.key === 'Enter') {
            dispatch(createCommentThreadThunk({
                recordingId: recording.id,
                spaceId: spaceId,
                data: {message: commentInputValue, time: commentInputPosition}
            })).then(() => dispatch(getCommentThreadsThunk({spaceId: spaceId, recordingId: recording.id}))).then(() => {
                setCommentInputPosition(null)
                setCommentInputValue("")
                waveformRef.current.focus()
            })
        }
    }

    const changeCommentInputValue = e => {
        setCommentInputValue(e.target.value);
    };

    return (

        <>
            <Row>
                <Col xs={2} md={1}>
                    <Button onClick={playPause} style={{height: "100%", width: "100%"}} type="default"
                            icon={isPlaying ? <PauseCircleOutlined/> : <PlayCircleOutlined/>}/>
                </Col>
                <Col xs={22} md={23}>
                    <div className="player-container" ref={waveformRef}/>
                    {recording && comments && isReady ?
                        (<div onClick={handleWaveClick} className={"overlay"}>
                            {comments.map(comment => displayComment(comment))}
                            {commentInputPosition ? (
                                <div className={"comment"}
                                     style={{left: `${100 * commentInputPosition / wavesurfer.getDuration()}%`}}>
                                    <MessageTwoTone/>
                                </div>
                            ) : null}

                        </div>) : null}
                    <Input ref={commentInputRef} onClick={enterCommentInput}
                           onBlur={() => setCommentInputPosition(null)} className={"comment-input"}
                           value={commentInputValue}
                           onChange={changeCommentInputValue}
                           addonBefore={<MessageTwoTone/>} placeholder={"Write a comment!"}
                           onKeyDown={sendCommentThread}/>
                </Col>
                <Col xs={2} md={2}>

                </Col>
            </Row>


        </>
    )
};
