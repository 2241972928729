import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {getHealth} from "./API";

export const getHealthThunk = createAsyncThunk(
    'health/getHealth',
    async () => {
        return getHealth()
    }
)

const HealthSlice = createSlice({
    name: 'availabilities',
    initialState: {health: {}, loading: 'idle'},
    reducers: {},
    extraReducers: {
        [getHealthThunk.pending]: (state, action) => {
            state.status = 'loading'
        },
        [getHealthThunk.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        },
        // Add reducers for additional action types here, and handle loading state as needed
        [getHealthThunk.fulfilled]: (state, action) => {
            // Add user to the state array
            state.health = action.payload
        }
    }
})

export default HealthSlice.reducer
