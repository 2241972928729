import {AutoComplete, Tag, Tooltip} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import {useEffect, useState} from "react";
import "./EditableLabelGroup.css"

export const EditableLabelGroup = ({labels, selectedLabels, change}) => {
    const existingTags = selectedLabels.map(selectedLabel => {
        return {label: selectedLabel.label_name, value: selectedLabel.id}
    })

    const [tags, setTags] = useState(existingTags)

    useEffect(() => {
        if(!((tags.length === existingTags.length) && (tags.every(val => existingTags.map(tag => tag.value).includes(val.value))))){
            change(tags);
        }
    }, [tags, change, existingTags]);

    const processedLabels = labels.map(song => {
        return {label: song.label_name, value: song.id}
    })

    const [inputVisible, setInputVisible] = useState(false)
    const [inputValue, setInputValue] = useState('')


    const handleClose = removedTag => {
        setTags(tags.filter(tag => tag !== removedTag))
    };

    const showInput = () => {
        setInputVisible(true)
    };

    const handleInputChange = value => {
        setInputValue(value)
    };

    const handleInputConfirm = () => {
        if (inputValue) {
            setTags([...tags, {label: inputValue, value: null}])
        }
        setInputValue('')
        setInputVisible(false)
    };

    const handleSelectConfirm = (value) => {

        const selectedLabel = labels.find(song => song.id === value)
        if (selectedLabel) {
            setTags([...tags, {label: selectedLabel.label_name, value: selectedLabel.id}])
        }
        setInputValue('')
        setInputVisible(false)
    };

    return (
        <div className={"editable-label-group"}>
            {tags.map((tag, index) => {
                const isLongTag = tag.label.length > 20;

                const tagElem = (
                    <Tag
                        className="edit-tag"
                        key={tag.label}
                        closable={true}
                        onClose={() => handleClose(tag)}
                    >
                        <span>
                            {isLongTag ? `${tag.label.slice(0, 20)}...` : tag.label}
                        </span>
                    </Tag>
                );
                return isLongTag ? (
                    <Tooltip title={tag.label} key={tag.label}>
                        {tagElem}
                    </Tooltip>
                ) : (
                    tagElem
                );
            })}
            {inputVisible && (
                <AutoComplete
                    options={processedLabels}
                    filterOption={(input, option) =>
                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    style={{width: 200}}
                    onSearch={handleInputChange}
                    onBlur={handleInputConfirm}
                    onSelect={handleSelectConfirm}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            handleInputConfirm();
                        }
                    }}
                    placeholder="search or create label"
                />
            )}
            {!inputVisible && (
                <Tag className="site-tag-plus" onClick={showInput}>
                    <PlusOutlined/> New Label
                </Tag>
            )}
        </div>
    );
}
