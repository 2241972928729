import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {getCurrentUser} from "./API";

export const getCurrentUserThunk = createAsyncThunk(
    'users/getCurrent',
    async (_) => {
        return getCurrentUser()
    }
)

const UsersSlice = createSlice({
    name: 'users',
    initialState: {current: null, loading: 'idle'},
    reducers: {},
    extraReducers: {
        [getCurrentUserThunk.pending]: (state, action) => {
            state.status = 'loading'
        },
        [getCurrentUserThunk.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        },
        // Add reducers for additional action types here, and handle loading state as needed
        [getCurrentUserThunk.fulfilled]: (state, action) => {
            // Add user to the state array
            state.current = action.payload
        }
    }
})

export default UsersSlice.reducer

