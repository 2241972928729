import {useDispatch, useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import React from "react";
import Button from "@material-ui/core/Button";
import {UserMenu} from "./UserMenu";
import {Layout, Space} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import {exitSpace} from "../Feature/SpacesSlice";
import "./Navbar.css"

export const Navbar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = useSelector((state) => {
        return state.firebase.auth
    });

    const currentSpace = useSelector((state) => {
        return state.spaces.current
    })

    const openLogin = () => {
        navigate('/login')
    };

    const exitSpaceAction = () => {
        dispatch(exitSpace())
        navigate('/spaces/')
    };

    return (
        <Layout.Sider
            breakpoint="lg"
            collapsedWidth="0"
        >
            <Space direction="vertical">
                <PageHeader
                    className="site-page-header"
                    avatar={{src: process.env.PUBLIC_URL + '/avocado_logo.png'}}
                    title={"Avocados"}
                />
                {currentSpace.data ?
                    <PageHeader
                        className="site-page-header"
                        onBack={exitSpaceAction}
                        title={currentSpace.data.space_name}
                        style={{paddingBottom: 0, paddingTop: 0}}
                    />
                    :
                    <></>
                }

                {user.isEmpty ? <Button onClick={openLogin} color="inherit">Login</Button> :
                    <UserMenu/>}
            </Space>

        </Layout.Sider>
    )
};
