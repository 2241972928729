import React, {useEffect} from "react";
import {Route, Routes, useNavigate, useLocation} from "react-router-dom";
import {ViewRecordings} from "./Views/ViewRecordings";
import {Authentication} from "./Views/Authentication";
import {UploadRecordings} from "./Views/UploadRecordings";
import {useDispatch, useSelector} from "react-redux";
import {getCurrentSpaceThunk} from "./Feature/SpacesSlice";
import {SelectSpace} from "./Views/SelectSpace";
import {SpaceSettings} from "./Views/SpaceSettings";
import {Login} from "./Views/Login";
import {CalendarView} from "./Views/CalendarView";
import AuthLayout from "./Components/AuthLayout";

export const RouterComponent = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const user = useSelector((state) => {
        return state.firebase.auth
    });

    useEffect(() => {
        const regex = /(.*)\/spaces\/(.*?)\/(.*)/;

        const matches = location.pathname.match(regex)
        if (user && matches) {
            const spaceId = matches[2]
            console.log(spaceId)
            dispatch(getCurrentSpaceThunk(spaceId)).catch(err => {
                navigate('/spaces')
            })
        }
    }, [user, dispatch, location.pathname, navigate]);

    return (
        <Routes>
            <Route path="/spaces" element={<AuthLayout />}>
                <Route path="/spaces/:spaceId/recordings" element={<ViewRecordings/>}/>
                <Route path="/spaces/:spaceId/settings" element={<SpaceSettings/>}/>
                <Route path="/spaces/:spaceId/upload-recordings" element={<UploadRecordings/>}/>
                <Route path="/spaces/:spaceId/calendar" element={<CalendarView/>}/>
                <Route path="/spaces/:spaceId/" element={<ViewRecordings/>}/>
                <Route path="/spaces" element={<SelectSpace/>}/>
            </Route>
            <Route exact path="/login" element={<Login/>}/>
            <Route  path="/" element={<Authentication/>}/>
        </Routes>
    );
}
