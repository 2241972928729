import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {getAllRecordings, updateRecording} from "./API";

export const getRecordingsThunk = createAsyncThunk(
    'recordings/getRecordings',
    async (spaceId) => {
        return getAllRecordings(spaceId)
    }
)

export const updateRecordingThunk = createAsyncThunk(
    'recordings/updateRecording',
    (data) => {
        return updateRecording(data.spaceId, data.data)
    }
)


const RecordingsSlice = createSlice({
    name: 'recordings',
    initialState: {recordings: [], currentRecording: null, loading: 'idle'},
    reducers: {
        updateCurrentRecording(state, action) {
            state.currentRecording = action.payload
        }
    },
    extraReducers: {
        [getRecordingsThunk.pending]: (state, action) => {
            state.status = 'loading'
        },
        [getRecordingsThunk.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        },
        // Add reducers for additional action types here, and handle loading state as needed
        [getRecordingsThunk.fulfilled]: (state, action) => {
            // Add user to the state array
            state.recordings = action.payload
        }
    }
})

export default RecordingsSlice.reducer
export const {updateCurrentRecording} = RecordingsSlice.actions
