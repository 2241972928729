import './App.css';
import React, {useEffect} from 'react';
import {RouterComponent} from "./RouterComponent";
import {Navbar} from "./Components/Navbar";
import {Layout} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {Footer} from "./Components/Footer";
import {getCurrentUserThunk} from "./Feature/UsersSlice";
import {getSpacesThunk} from "./Feature/SpacesSlice";
import {getHealthThunk} from "./Feature/HealthSlice";
import {ErrorScreen} from "./Views/ErrorScreen";

const MINUTE_MS = 60000;

function App() {

    const dispatch = useDispatch();

    const user = useSelector((state) => {
        return state.firebase.auth
    });

    const isHealthy = useSelector((state) => {
        return state.health.health.status === 'UP'
    })

    useEffect(() => {
        dispatch(getHealthThunk())
        const interval = setInterval(() => {
            dispatch(getHealthThunk())
        }, MINUTE_MS);
        return () => clearInterval(interval);
    }, [dispatch])


    useEffect(() => {
        dispatch(getCurrentUserThunk()).then(() => dispatch(getSpacesThunk()))
    }, [dispatch, user]);

    return (<>
            {user.isEmpty ? <RouterComponent/> :
                (<Layout style={{minHeight: "100vh"}}>
                    <Navbar/>
                    <Layout>
                        <Layout.Content style={{margin: '24px 16px 0'}}>
                            {
                                isHealthy ? <RouterComponent/> : <ErrorScreen/>
                            }
                        </Layout.Content>
                        <Footer/>
                    </Layout>
                </Layout>)}
        </>

    );
}

export default App;
