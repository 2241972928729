import {Layout} from 'antd';
import "./Footer.css"
import React from "react";
import {Player} from "./Player";

export const Footer = () => {

    return (
        <Layout.Footer style={{position: "sticky", bottom: "0"}}>
            <Player/>
        </Layout.Footer>
    )
};
