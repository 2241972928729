import React, {useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {getRecordingsThunk, updateCurrentRecording, updateRecordingThunk} from "../Feature/RecordingsSlice";

import {getLabelsThunk} from "../Feature/LabelsSlice";
import {Card, message, Table} from "antd";
import {PlayCircleOutlined} from "@ant-design/icons";
import {createLabel} from "../Feature/API";
import moment from "moment";
import DownloadOutlined from "@ant-design/icons/lib/icons/DownloadOutlined";
import "./ViewRecordings.css"
import {EditableLabelGroup} from "../Components/EditableLabelGroup";

export const ViewRecordings = () => {
    const dispatch = useDispatch();

    const spaceId = useSelector((state) => {
        if (state.spaces.current.data) {
            return state.spaces.current.data.id
        } else {
            return null
        }
    })

    const recordings = useSelector((state) => {
        return state.recordings.recordings
    })

    const labels = useSelector((state) => {
        return state.labels.labels
    })

    const user = useSelector((state) => {
        return state.firebase.auth
    });

    useEffect(() => {
        dispatch(getLabelsThunk(spaceId)).then(dispatch(getRecordingsThunk(spaceId)))

    }, [dispatch, spaceId, user]);

    const createLabels = (labels) => {
        return Promise.all(labels.map(label => createLabel(spaceId, {"label_name": label.label})))
            .then(async labels => {
                await dispatch(getLabelsThunk(spaceId));
                return labels.map(label => {
                    return {label: label.label_name, value: label.id}
                })
            })
    }

    const updateRecording = (record, labels) => {
        message.loading({content: `Updating record ${record.original_name}`, key: record.id});
        const existingLabels = labels.filter(label => label.value)
        createLabels(labels.filter(label => !label.value)).then(
            newLabels => {
                console.log("updating");
                return dispatch(updateRecordingThunk({
                    spaceId: spaceId, data: {
                        recording_id: record.id,
                        label_ids: [...newLabels, ...existingLabels].map(label => label.value)
                    }
                }))
            }).then(() => {
            console.log("reloading");
            return dispatch(getRecordingsThunk(spaceId))
        })
            .then(() => message.success({
                content: `Record ${record.original_name} was updated`,
                key: record.id
            }))


    }

    const playRecording = (recording) => {
        dispatch(updateCurrentRecording(recording))
    }

    const labelFilters = labels.map(label => {
        return {text: label.label_name, value: label.id}
    })

    const columns = [
        {
            title: 'Original Name',
            dataIndex: 'original_name',
            key: 'original_name',
            responsive: ["sm"]
        },
        {
            title: 'Listen',
            key: 'file_uri',
            render: (text, record) => {
                return (<>
                    <Card className={"recoding-card"}
                          actions={[
                              <PlayCircleOutlined key="play" onClick={() => playRecording(record)}/>,
                              <a href={record.file_uri} download><DownloadOutlined key="download"/></a>
                          ]}
                    >
                    </Card>
                </>)
            },
            responsive: ["sm"]
        },
        {
            title: 'Recording Date',
            dataIndex: 'creation_date_time',
            sorter: (a, b) => moment(a.creation_date_time).isBefore(moment(b.creation_date_time)),
            key: 'creation_date_time',
            render: datetime => moment(datetime).utc().format('YYYY-MM-DD'),
            responsive: ["sm"]
        },
        {
            title: 'Labels',
            key: 'song',
            filters: [
                ...labelFilters
            ],
            onFilter: (value, record) => {
                return record.labels.some(label =>label.id.indexOf(value) === 0)
            },
            render: (text, record) => {
                return (<EditableLabelGroup key={record.labels.length} labels={labels}
                                            selectedLabels={record.labels}
                                            change={(labels) => updateRecording(record, labels)}/>)
            },
            responsive: ["sm"]
        },
        {
            title: 'Song',
            key: 'file_uri',
            filters: [
                ...labelFilters
            ],
            onFilter: (value, record) => {
                return record.labels.some(label =>label.id.indexOf(value) === 0)
            },
            sorter: (a, b) => moment(a.creation_date_time).isBefore(moment(b.creation_date_time)),
            render: (text, record) => {
                return (<>
                    <Card
                        actions={[
                            <PlayCircleOutlined key="play" onClick={() => playRecording(record)}/>,
                            <a href={record.file_uri} download><DownloadOutlined key="download"/></a>
                        ]}
                        title={record.original_name}
                    >
                        <Card.Meta
                            description={`Recorded on ${moment(record.creation_date_time).utc().format('YYYY-MM-DD')}`}
                        />
                        <br/>
                        <EditableLabelGroup key={record.labels.length} labels={labels}
                                            selectedLabels={record.labels}
                                            change={(labels) => updateRecording(record, labels)}/>

                    </Card>
                </>)
            },
            responsive: ["xs"]
        }]

    return (
        <>
            <h1>
                Recordings
            </h1>

            <Table key={labels.length} rowKey="id" columns={columns} dataSource={recordings}/>
        </>
    );
}
