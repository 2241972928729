import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {createAvailability, deleteAvailability, getAllAvailabilities} from "./API";

export const getAvailabilitiesThunk = createAsyncThunk(
    'availabilities/getAvailabilities',
    async (spaceId) => {
        return getAllAvailabilities(spaceId)
    }
)

export const deleteAvailabilityThunk = createAsyncThunk(
    'availabilities/deleteAvailability',
    ({spaceId, availabilityId}) => {
        return deleteAvailability(spaceId, availabilityId)
    }
)

export const createAvailabilityThunk = createAsyncThunk(
    'availabilities/deleteAvailability',
    ({spaceId, date}) => {
        return createAvailability(spaceId, {date: date, preference: 1, spaceId: spaceId})
    }
)

const AvailabilitiesSlice = createSlice({
    name: 'availabilities',
    initialState: {availabilities: [], loading: 'idle'},
    reducers: {},
    extraReducers: {
        [getAvailabilitiesThunk.pending]: (state, action) => {
            state.status = 'loading'
        },
        [getAvailabilitiesThunk.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        },
        // Add reducers for additional action types here, and handle loading state as needed
        [getAvailabilitiesThunk.fulfilled]: (state, action) => {
            // Add user to the state array
            state.availabilities = action.payload
        }
    }
})

export default AvailabilitiesSlice.reducer
