import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux'
import {rrfProps, store} from './store'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {HashRouter} from "react-router-dom";
import {ReactReduxFirebaseProvider} from "react-redux-firebase";
import "antd/dist/reset.css";

ReactDOM.render(
    <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
            <HashRouter>
                <App/>
            </HashRouter>
        </ReactReduxFirebaseProvider>
    </Provider>,
    document.getElementById('root')
);

reportWebVitals();
