import React, {useEffect, useState} from "react";
import {useFirebase} from "react-redux-firebase";
import {GoogleAuthProvider, signInWithPopup} from "firebase/auth"
import TextField from "@material-ui/core/TextField";
import {Button, Grid} from "@material-ui/core"
import Box from "@material-ui/core/Box";

import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Typography from "@material-ui/core/Typography";
import {auth} from "../store";
import {Search} from "@material-ui/icons";


const Join = ({isJoin}) => {
    const dispatch = useDispatch();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setErrors] = useState();
    const firebase = useFirebase();

    const navigate = useNavigate();
    const user = useSelector((state) => {
        return state.firebase
    });

    useEffect(() => {
        if (user.auth.isLoaded && user.auth.isEmpty === false) {
            const redirect = new URL(window.location.href).searchParams.get('redirect')
            if (redirect) {
                navigate(redirect)
            } else {
                navigate('/spaces')
            }
        }
    }, [navigate, user]);


    const handleForm = e => {
        e.preventDefault();
        if (isJoin) {
            firebase
                .auth()
                .createUserWithEmailAndPassword(email, password)
                .then(res => {
                    console.log(res)
                })
                .catch(e => {
                    setErrors(e.message);
                });
        } else {
            firebase
                .auth()
                .signInWithEmailAndPassword(email, password)
                .then(res => {
                    console.log(res)
                })
                .catch(e => {
                    setErrors(e.message);
                });
        }
    };

    const loginWithGoogle = () => {
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider)
            .then((result) => {
                dispatch({
                    type: 'LOGIN_USER_SUCCESS',
                    payload: result
                })
                console.log(result);
                navigate("/spaces");
            })
            .catch((error) => {
                setErrors(error.message); // Handle errors here
            });
    };

    return (
        <>
            <Typography style={{textAlign: "center", marginBottom: "30px"}} variant="h4" gutterBottom>
                {isJoin ? "Register" : "Login"}
            </Typography>
            <form onSubmit={e => handleForm(e)}>
                <Grid
                    container
                    spacing={3}
                    justify={'center'}
                    alignItems={'center'}
                >
                    <Grid item xs={10} md={7}>
                        <TextField required
                                   label="Email"
                                   onChange={e => setEmail(e.target.value)}
                                   fullWidth={true}
                                   variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={10} md={7}>
                        <TextField
                            onChange={e => setPassword(e.target.value)}
                            id="standard-password-input"
                            label="Password"
                            type="password"
                            autoComplete="current-password"
                            fullWidth={true}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={10} md={7}>
                        <Button variant={"contained"} color="primary" type="submit" fullWidth>
                            {isJoin ? 'S\'inscrire' : 'Se connecter'}
                        </Button>
                        <Box m={1}/>
                        <Button onClick={loginWithGoogle} variant={"contained"} fullWidth startIcon={<Search/>}>Login
                            with Google</Button>
                    </Grid>
                </Grid>

                <span>{error}</span>
            </form>
        </>
    );
};

export default Join;
