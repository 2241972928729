import request, {requestWithoutAuth, uploadFile} from "./requests";
import moment from "moment";

export var SERVER = () => {
    if (process.env.NODE_ENV === 'development') {
        return 'http://localhost:8081'
    } else {
        return 'https://band-api.avocados.ovh'
    }
}

export const getHealth = async () => {
    let data = (await requestWithoutAuth(`${SERVER()}/actuator/health`, {method: 'GET'})).data
    console.log(data)
    return data

}

export const getCurrentUser = async () => {
    return (await request(`${SERVER()}/api/users/me`, {method: 'GET'})).data
}

export const getCurrentSpace = async (spaceId) => {
    return (await request(`${SERVER()}/api/spaces/${spaceId}/`, {method: 'GET'})).data
}

export const getCurrentSpaceStatistics = async (spaceId) => {
    return (await request(`${SERVER()}/api/spaces/${spaceId}/statistics`, {method: 'GET'})).data
}

export const getAllRecordings = async (spaceId) => {
    return (await request(`${SERVER()}/api/spaces/${spaceId}/recordings/`, {method: 'GET'})).data
}

export const getAllCommentThreads = async (spaceId, recordingId) => {
    return (await request(`${SERVER()}/api/spaces/${spaceId}/recordings/${recordingId}/threads/`, {method: 'GET'})).data
}

export const createCommentThread = async (spaceId, recordingId, data) => {
    return (await request(`${SERVER()}/api/spaces/${spaceId}/recordings/${recordingId}/threads/`, {
        method: 'POST',
        body: data
    })).data
}

export const getAllLabels = async (spaceId) => {
    return (await request(`${SERVER()}/api/spaces/${spaceId}/labels/`, {method: 'GET'})).data
}

export const getAllAvailabilities = async (spaceId) => {
    return (await request(`${SERVER()}/api/spaces/${spaceId}/availabilities/`, {method: 'GET'})).data
}

export const deleteAvailability = async (spaceId, availabilityId) => {
    return (await request(`${SERVER()}/api/spaces/${spaceId}/availabilities/${availabilityId}/`, {method: 'DELETE'})).data
}

export const createAvailability = async (spaceId, data) => {
    return (await request(`${SERVER()}/api/spaces/${spaceId}/availabilities/`, {
        method: 'POST',
        body: data
    })).data
}

export const getAllSpaces = async () => {
    return (await request(`${SERVER()}/api/spaces/`, {method: 'GET'})).data
}

export const createSpace = async (data) => {
    return (await request(`${SERVER()}/api/spaces/`, {method: 'POST', body: data})).data
}

export const updateSpace = async (spaceId, data) => {
    return (await request(`${SERVER()}/api/spaces/${spaceId}/`, {method: 'PATCH', body: data})).data
}

export const createLabel = async (spaceId, data) => {
    return (await request(`${SERVER()}/api/spaces/${spaceId}/labels/`, {method: 'POST', body: data})).data
}

export const createRecording = async (spaceId, file) => {
    console.log(file)
    return (await request(`${SERVER()}/api/spaces/${spaceId}/recordings/`, {
        method: 'POST',
        body: {
            file_name: file.name,
            created_at: moment(file.lastModified).toISOString()
        }
    }).then(req => uploadFile(req.data, file))).data
}

export const updateRecording = async (spaceId, data) => {
    return (await request(`${SERVER()}/api/spaces/${spaceId}/recordings/${data.recording_id}/`, {
        method: 'PATCH',
        body: data
    })).data
}
