import React, {useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {getCurrentSpaceThunk, getSpacesThunk} from "../Feature/SpacesSlice";
import {Alert, Button, Card, Space} from "antd";
import {RightCircleOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import {CreateSpace} from "../Components/CreateSpace";


export const SelectSpace = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => {
        return state.firebase.auth
    });

    const spaces = useSelector((state) => {
        return state.spaces
    })

    useEffect(() => {
        dispatch(getSpacesThunk())
    }, [dispatch, user]);

    const enterSpace = (space) => {
        dispatch(getCurrentSpaceThunk(space.id)).then(() => navigate(`/spaces/${space.id}/`))
    }

    return (
        <>
            <h1>
                Your Spaces
            </h1>
            <CreateSpace/>
            <Space direction="vertical" style={{width: '100%'}}>
                {spaces.spaces && spaces.spaces.length !== 0 ? (<>

                        {spaces.spaces.map(s =>
                            <Card key={s.id} title={`${s.space_name} Space`} bordered={false}>
                                <Button onClick={() => enterSpace(s)} type="primary" icon={<RightCircleOutlined/>}>
                                    {`Enter ${s.space_name} Space`}
                                </Button>
                            </Card>)}
                    </>)
                    : <Alert message="You are not part of a space yet." type="info"/>}


            </Space>
        </>
    );
}
