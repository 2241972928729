import React from "react";
import {Button, Col, List, Row} from "antd";
import Title from "antd/lib/typography/Title";
import {AudioOutlined, CalendarOutlined, CommentOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import "./Banner.css"

const Banner = () => {

    const data = [
        {
            title: 'Collaborative edition of music sheets',
            description: 'Create music scores and collaborate with others to edit comment and review changes.',
            icon: <AudioOutlined style={{fontSize: '30px'}}/>
        },
        {
            title: 'Comment your recordings',
            description: 'Allow other members of your space to comment the recordings. This can be useful if you want to highlight a specific par of a rehearsal.',
            icon: <CommentOutlined style={{fontSize: '30px'}}/>
        },
        {
            title: 'Managed Calendar',
            description: 'Finding a time for the next rehearsal, reminding the band when is the next concert can be challenging. We offer an integrate tool to manage this for you.',
            icon: <CalendarOutlined style={{fontSize: '30px'}}/>
        }
    ];

    return (
        <div className='site-banner'>
            <Row gutter={16}>
                <Col className="gutter-row" xs={1} md={10}>
                </Col>
                <Col className='site-pitch' xs={22} md={12}>

                    <Title>Avocados</Title>

                    <p>This is a beta release of the website. We want to provide to musicians a space to manage their
                        projects. We currently offer 10GB of free space to store your music.</p>

                    <p>More features are to come:</p>

                    <List
                        itemLayout="horizontal"
                        dataSource={data}
                        renderItem={item => (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={item.icon}
                                    title={<a href="https://ant.design">{item.title}</a>}
                                    description={item.description}
                                />
                            </List.Item>
                        )}
                    />

                    <p>Please give us feedback and request new features via email <a
                        href="mailto:avocados@gmail.com">whereweband@gmail.com</a></p>
                    <Link to="/login"><Button style={{width: "100%"}} type="primary">Register / Login</Button></Link>
                </Col>
                <Col className="gutter-row" xs={1} md={2}>
                </Col>

            </Row>
        </div>
    )

};

export default Banner;
