import React, {useEffect} from "react";
import {Button, Layout} from 'antd';
import Banner from "../Components/Banner";
import {Link, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import "./Authentication.css"

const {Header, Content, Footer} = Layout;

export const Authentication = () => {
    const navigate = useNavigate();
    const user = useSelector((state) => {
        return state.firebase
    });

    useEffect(() => {
        if (user.auth.isLoaded && user.auth.isEmpty === false) {
            navigate('/spaces')
        }
    }, [navigate, user]);

    return (
        <Layout className="layout">
            <Header style={{position: 'fixed', zIndex: 1, width: '100%'}}>
                <Link to="/">
                    <div className="logo"/>
                    <div className="site-title">My Bands</div>
                </Link>
                <div className="login-button">
                    <Link to="/login"><Button type="primary">Register / Login</Button></Link>
                </div>
            </Header>
            <Content className="site-layout" style={{padding: '0', marginTop: 64}}>
                <Banner/>
            </Content>
            <Footer style={{textAlign: 'center'}}>Avocados ©2022</Footer>
        </Layout>
    )
};
