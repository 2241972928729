import React, {useState} from 'react'
import {Button, Form, Input, message, Modal} from "antd";
import {PlusCircleOutlined} from "@ant-design/icons";
import {createSpaceThunk, getSpacesThunk} from "../Feature/SpacesSlice";
import {useDispatch} from "react-redux";


export const CreateSpace = () => {
    const dispatch = useDispatch();

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        form
            .validateFields()
            .then((values) => {
                form.resetFields();
                setIsModalVisible(false);
                dispatch(createSpaceThunk(values))
                    .then(() => dispatch(getSpacesThunk()))
                    .then(() => message.success(`Created space ${values.spaceName}`))

            })

    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onFinish = (values) => {
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const [form] = Form.useForm();


    return (
        <>

            <Button style={{width: '100%'}} type="dashed" icon={<PlusCircleOutlined/>} onClick={showModal}>
                Create new space
            </Button>

            <Modal title="Create Space" open={isModalVisible} okText={"Create Space"} onOk={handleOk}
                   onCancel={handleCancel}>
                <Form
                    form={form}
                    name="basic"
                    labelCol={{span: 8}}
                    wrapperCol={{span: 16}}
                    initialValues={{remember: true}}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Space Name"
                        name="spaceName"
                        rules={[{required: true, message: 'Please enter a space name!'}]}
                    >
                        <Input/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}
