import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {getAllLabels} from "./API";

export const getLabelsThunk = createAsyncThunk(
    'songs/getLabels',
    async (spaceId) => {
        return getAllLabels(spaceId)
    }
)

const LabelsSlice = createSlice({
    name: 'labels',
    initialState: {labels: [], loading: 'idle'},
    reducers: {},
    extraReducers: {
        [getLabelsThunk.pending]: (state, action) => {
            state.status = 'loading'
        },
        [getLabelsThunk.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        },
        // Add reducers for additional action types here, and handle loading state as needed
        [getLabelsThunk.fulfilled]: (state, action) => {
            // Add user to the state array
            state.labels = action.payload
        }
    }
})

export default LabelsSlice.reducer
