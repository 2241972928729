import React from 'react'
import {Space} from "antd";

export const ErrorScreen = () => {

    return (
        <>
            <h1>
                Server is not ready...
            </h1>

            <Space direction="vertical" style={{width: '100%'}}>
                I do not pay for the server, so it takes 30 seconds to wake up.
            </Space>

        </>
    );
}
