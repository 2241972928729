import React, {useEffect} from 'react';
import {Alert, Button, Divider, Form, Input, message} from 'antd';
import UserEmailField from "../Components/UserEmailField";
import {useDispatch, useSelector} from "react-redux";
import {
    getCurrentSpaceStatisticsThunk,
    getCurrentSpaceThunk,
    getSpacesThunk,
    updateSpaceThunk
} from "../Feature/SpacesSlice";
import formatBytes from "../Utils/conversions";

const defaultFormItemLayout = {
    labelCol: {
        xs: {span: 6}
    },
    wrapperCol: {
        xs: {span: 12}
    }
};


export const SpaceSettings = () => {
    const dispatch = useDispatch();
    const currentSpace = useSelector((state) => {
        return state.spaces.current
    })

    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue(currentSpace.data)
        if (currentSpace.data) {
            dispatch(getCurrentSpaceStatisticsThunk(currentSpace.data.id))
        }
    }, [dispatch, form, currentSpace.data]);

    function handleFinish(values) {
        const payload = {spaceId: currentSpace.data.id, data: values}
        dispatch(updateSpaceThunk(payload))
            .then(() => dispatch(getSpacesThunk()))
            .then(() => dispatch(getCurrentSpaceThunk()))
            .then(() => message.success("Space was updated."))
    }

    return (
        <>
            <h1>Settings</h1>
            <Divider/>
            {currentSpace.statistics ?
                <Alert message={`You are using ${formatBytes(currentSpace.statistics.size)} / 1GB`} type="success"/>
                : null
            }
            <Divider/>
            <Form form={form} {...defaultFormItemLayout} onFinish={handleFinish}>
                <h4>Space Name</h4>
                <Form.Item
                    name="space_name"
                    rules={[{required: true}]}
                >
                    <Input/>
                </Form.Item>
                <Divider/>
                <h4>Space Members</h4>
                <UserEmailField/>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </>

    );

}
