import React from 'react';
import { useSelector } from 'react-redux';
import {Navigate, useOutlet, useLocation} from 'react-router-dom';

const AuthLayout = () => {
    const outlet = useOutlet();
    const location = useLocation();
    const user = useSelector((state) => state.firebase.auth);

    if (user.isEmpty) {
        return <Navigate to={`/login?redirect=${location.pathname}`} replace />;
    }
    return outlet;
};

export default AuthLayout;
