import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {createCommentThread, getAllCommentThreads} from "./API";

export const getCommentThreadsThunk = createAsyncThunk(
    'commentThreads/getCommentThreads',
    async ({spaceId, recordingId}) => {
        return getAllCommentThreads(spaceId, recordingId)
    }
)

export const createCommentThreadThunk = createAsyncThunk(
    'commentThreads/createCommentThreadThunk',
    ({spaceId, recordingId, data}) => {
        return createCommentThread(spaceId, recordingId, data)
    }
)

const CommentThreadsSlice = createSlice({
    name: 'commentThreads',
    initialState: {commentThreads: [], loading: 'idle'},
    reducers: {},
    extraReducers: {
        [getCommentThreadsThunk.pending]: (state, action) => {
            state.status = 'loading'
        },
        [getCommentThreadsThunk.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        },
        // Add reducers for additional action types here, and handle loading state as needed
        [getCommentThreadsThunk.fulfilled]: (state, action) => {
            // Add user to the state array
            state.commentThreads = action.payload
        }
    }
})

export default CommentThreadsSlice.reducer

