import RecordingsSlice from './Feature/RecordingsSlice'
import LabelsSlice from "./Feature/LabelsSlice";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth'
import 'firebase/compat/database';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import {firebaseReducer, getFirebase} from 'react-redux-firebase'
import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import thunk from 'redux-thunk';
import UsersSlice from "./Feature/UsersSlice";
import SpacesSlice from "./Feature/SpacesSlice";
import CommentThreadsSlice from "./Feature/CommentThreadsSlice";
import AvailabilitySlice from "./Feature/AvailabilitySlice";
import HealthSlice from "./Feature/HealthSlice";

const fbConfig = {
    apiKey: "AIzaSyDe6dn88yP7p7h4opBve50J25JRjOXEb_Y",
    authDomain: "avocados-4988d.firebaseapp.com",
    projectId: "avocados-4988d",
    storageBucket: "avocados-4988d.appspot.com",
    messagingSenderId: "148931289170",
    appId: "1:148931289170:web:604246b92ccd85ca993f8b",
    measurementId: "G-214Q5MNJF9"
}

const middlewares = [
    thunk.withExtraArgument(getFirebase)
]

// react-redux-firebase config
const rrfConfig = {
    userProfile: 'users'
    // useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
    // enableClaims: true // Get custom claims along with the profile
}

const app = initializeApp(fbConfig);
const db = getFirestore(app);
const auth = getAuth(app);
firebase.initializeApp(fbConfig)

export {db, auth};

const rootReducer = combineReducers({
    recordings: RecordingsSlice,
    labels: LabelsSlice,
    users: UsersSlice,
    spaces: SpacesSlice,
    health: HealthSlice,
    commentThreads: CommentThreadsSlice,
    availabilities: AvailabilitySlice,
    firebase: firebaseReducer
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const initialState = {};
export const store = createStore(rootReducer, initialState, compose(
    applyMiddleware(...middlewares),
    composeEnhancers(applyMiddleware(thunk)),
    window.devToolsExtension ? window.devToolsExtension() : f => f
))

export const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch
}
