import React from "react";
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Join from "../Components/Join";
import Box from "@material-ui/core/Box";
import {Button, Layout} from "antd";
import {Link} from "react-router-dom";
import {Footer, Header} from "antd/lib/layout/layout";
import {Content} from "antd/es/layout/layout";
import "./Login.css"

export const Login = () => {
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Layout className="layout">
            <Header style={{position: 'fixed', zIndex: 1, width: '100%'}}>
                <Link to="/">
                    <div className="logo"/>
                    <div className="site-title">Avocados</div>
                </Link>
                <div className="login-button">
                    <Link to="/login"><Button type="primary">Register / Login</Button></Link>
                </div>
            </Header>
            <Content className="site-layout" style={{padding: '0', marginTop: 64}}>
                <Box m={5}/>
                <Paper square style={{maxWidth: 700, margin: 'auto'}}>
                    <Tabs
                        value={value}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChange}
                        style={{padding: 0}}
                        centered
                    >
                        <Tab label="Join"/>
                        <Tab label="Login"/>
                    </Tabs>
                    <Box m={5}/>
                    <Join key={value} isJoin={value === 0}/>
                </Paper>
                <Box m={5}/>
            </Content>
            <Footer style={{textAlign: 'center'}}>Avocados ©2022</Footer>
        </Layout>


    )

};
